<template>
  <div>
    <div>
      <div class="title">
        <div class="title_t" style="margin-bottom: 0;">
          <span>司机信息</span>
        </div>
        <div class="title_r" @click="returnPage">
          <span>返回</span>
        </div>
      </div>

      <el-form ref="form" :model="form" label-width="100px">
        <el-card shadow="never" style="margin-top: 20px;">
          <el-row style="display: flex;align-items: center;">
            <span class="span_l" style="width: 5px;height: 19px;background: #ff9b05;display: inline-block;margin-right: 10px;"></span>
            <span style="font-size: 20px;font-weight: 700;color: #202033;">基本信息</span>
          </el-row>

          <el-row :gutter="15" style="margin-bottom: 15px;margin-top: 15px;">
            <el-col :span="8">
              <span class="span_l" style="font-size: 16px;font-weight: 400;color: #868792;">司机姓名：</span>
              <span class="span_r" style="font-size: 16px;font-weight: 400;color: #050505;">{{form.realname}}</span>
            </el-col>
            <el-col :span="8">
              <span class="span_l" style="font-size: 16px;font-weight: 400;color: #868792;">在线状态：</span>
              <span>
                <span class="span_r" style="font-size: 16px;font-weight: 400;">{{form.on_line_name}}</span>
              </span>
            </el-col>
            <el-col :span="8">
              <span class="span_l" style="font-size: 16px;font-weight: 400;color: #868792;">接单状态：</span>
              <span class="span_r" style="font-size: 16px;font-weight: 400;color: #FF9B05;">{{form.on_line_name}}</span>
            </el-col>
          </el-row>

          <el-row :gutter="15" style="margin-bottom: 15px;">
            <el-col :span="8">
              <span class="span_l" style="font-size: 16px;font-weight: 400;color: #868792;">联系电话：</span>
              <span class="span_r" style="font-size: 16px;font-weight: 400;color: #050505;">{{form.phone}}</span>
            </el-col>
            <el-col :span="8">
              <span class="span_l" style="font-size: 16px;font-weight: 400;color: #868792;">身份证号码：</span>
              <span class="span_r" style="font-size: 16px;font-weight: 400;color: #050505;">{{form.id_number}}</span>
            </el-col>
            <el-col :span="8">
              <span class="span_l" style="font-size: 16px;font-weight: 400;color: #868792;">身份证有效期：</span>
              <span class="span_r" style="font-size: 16px;font-weight: 400;color: #050505;" >{{form.id_valid_time}}</span>
            </el-col>
          </el-row>

          <el-row :gutter="15" style="margin-bottom: 15px;">
            <el-col :span="8">
              <span class="span_l" style="font-size: 16px;font-weight: 400;color: #868792;">身份证地址：</span>
              <span class="span_r" style="font-size: 16px;font-weight: 400;color: #050505;margin-right: 10px;">{{form.id_address}}</span>
            </el-col>
            <el-col :span="8">
              <span class="span_l" style="font-size: 16px;font-weight: 400;color: #868792;">服务区域：</span>
              <span class="span_r" style="font-size: 16px;font-weight: 400;color: #050505;margin-right: 10px;" v-for="(item) in form.city">{{item}}</span>
            </el-col>
            <!--<el-col :span="8">-->
            <!--  <span class="span_l" style="font-size: 16px;font-weight: 400;color: #868792;">驾驶证号码：</span>-->
            <!--  <span class="span_r" style="font-size: 16px;font-weight: 400;color: #050505;">{{form.drivers_number}}</span>-->
            <!--</el-col>-->
          </el-row>

          <el-row :gutter="15" style="margin-bottom: 15px;">
            <el-col :span="8">
              <span class="span_l" style="font-size: 16px;font-weight: 400;color: #868792;">驾驶证有效期：</span>
              <span class="span_r" style="font-size: 16px;font-weight: 400;color: #050505;margin-right: 10px;" >{{form.drivers_valid_time}}</span>
            </el-col>
            <el-col :span="8">
              <span class="span_l" style="font-size: 16px;font-weight: 400;color: #868792;">准驾车型：</span>
              <span class="span_r" style="font-size: 16px;font-weight: 400;color: #050505;">{{form.drivers_type}}</span>
            </el-col>
            <el-col :span="8">
              <span class="span_l" style="font-size: 16px;font-weight: 400;color: #868792;">司机状态：</span>
              <span class="span_r" style="font-size: 16px;font-weight: 400;color: #FF9B05;">{{form.status_name}}</span>
            </el-col>
          </el-row>

          <el-row :gutter="15" style="margin-bottom: 15px;">
            <el-col :span="8">
              <span class="span_l" style="font-size: 16px;font-weight: 400;color: #868792;">编号：</span>
              <span class="span_r" style="font-size: 16px;font-weight: 400;color: #050505;">{{form.id}}</span>
            </el-col>
            <el-col :span="8">
              <span class="span_l" style="font-size: 16px;font-weight: 400;color: #868792;">救援车辆：</span>
              <span class="span_r" style="font-size: 16px;font-weight: 400;color: #050505;">{{form.license_plate_number}}</span>
            </el-col>
            <el-col :span="8">
              <span class="span_l" style="font-size: 16px;font-weight: 400;color: #868792;">司机来源：</span>
              <span class="span_r" style="font-size: 16px;font-weight: 400;color: #050505;">{{form.source}}</span>
            </el-col>
          </el-row>

          <el-row :gutter="15" style="margin-bottom: 15px;">
            <el-col :span="8">
              <span class="span_l" style="font-size: 16px;font-weight: 400;color: #868792;">实名状态：</span>
              <span class="span_r" style="font-size: 16px;font-weight: 400;color: #050505;">{{form.autonym_status_name}}</span>
            </el-col>
            <el-col :span="8">
              <span class="span_l" style="font-size: 16px;font-weight: 400;color: #868792;">注册时间：</span>
              <span class="span_r" style="font-size: 16px;font-weight: 400;color: #050505;">{{form.create_time}}</span>
            </el-col>
            <el-col :span="8">
              <span class="span_l" style="font-size: 16px;font-weight: 400;color: #868792;">最后上线时间：</span>
              <span class="span_r" style="font-size: 16px;font-weight: 400;color: #050505;">{{form.last_time}}</span>
            </el-col>
          </el-row>

          <!--v-if="form.driver_type != 'self'"-->
          <el-row :gutter="15" style="margin-bottom: 15px;">
            <el-col :span="8">
              <span class="span_l" style="font-size: 16px;font-weight: 400;color: #868792;">驾驶信息认证：</span>
              <span style="font-size: 16px;font-weight: 400;color: #050505;">
                <span v-if="form.driving_license_approve == true">通过</span>
                <span v-else>未通过</span>
              </span>
            </el-col>
            <el-col :span="8">
              <span class="span_l" style="font-size: 16px;font-weight: 400;color: #868792;">车辆信息认证：</span>
              <span class="span_r" style="font-size: 16px;font-weight: 400;color: #050505;">
                <span v-if="form.vehicle_approve == true">通过</span>
                <span v-else>未通过</span>
              </span>
            </el-col>
            <el-col :span="8">
              <span class="span_l" style="font-size: 16px;font-weight: 400;color: #868792;">是否会员：</span>
              <span class="span_r" style="font-size: 16px;font-weight: 400;color: #050505;">{{form.vip_level_msg}}</span>
            </el-col>
          </el-row>
        </el-card>

        <!--证件照片-->
        <el-card shadow="never">
          <el-row style="display: flex;align-items: center;">
            <span class="span_l" style="width: 5px;height: 19px;background: #ff9b05;display: inline-block;margin-right: 10px;"></span>
            <span style="font-size: 20px;font-weight: 700;color: #202033;">基本信息</span>
          </el-row>
          <el-row :gutter="15" style="margin-top: 15px;">
            <el-col :span="6">
              <img :src="form.identification_photo_front" alt="" style="width: 100%;height: 240px;">
              <div style="font-size: 16px;font-weight: 400;color: #050505;text-align: center;margin-top: 10px;">身份证正面</div>
            </el-col>
            <el-col :span="6">
              <img :src="form.identification_photo_reverse" alt="" style="width: 100%;height: 240px;">
              <div style="font-size: 16px;font-weight: 400;color: #050505;text-align: center;margin-top: 10px;">身份证反面</div>
            </el-col>
            <el-col :span="6">
              <img :src="form.driving_license_front" alt="" style="width: 100%;height: 240px;">
              <div style="font-size: 16px;font-weight: 400;color: #050505;text-align: center;margin-top: 10px;">驾驶证主页</div>
            </el-col>
            <el-col :span="6">
              <img :src="form.driving_license_reverse" alt="" style="width: 100%;height: 240px;">
              <div style="font-size: 16px;font-weight: 400;color: #050505;text-align: center;margin-top: 10px;">驾驶证副页</div>
            </el-col>
          </el-row>
        </el-card>

        <!--账户明细-->
        <el-card shadow="never">
          <el-row style="display: flex;align-items: center;">
            <span class="span_l" style="width: 5px;height: 19px;background: #ff9b05;display: inline-block;margin-right: 10px;"></span>
            <span style="font-size: 20px;font-weight: 700;color: #202033;">账户明细</span>
          </el-row>

          <el-row :gutter="15" style="margin-top: 15px;">
            <el-col :span="6">
              <span style="font-size: 18px;font-weight: 500;color: #868792;">总业绩：</span>
              <span style="font-size: 18px;font-weight: 500;color: #202033;">{{data.total_performance}}</span>
            </el-col>
            <el-col :span="6">
              <span style="font-size: 18px;font-weight: 500;color: #868792;">本月收入：</span>
              <span style="font-size: 18px;font-weight: 500;color: #202033;">{{data.current_income}}</span>
            </el-col>
            <el-col :span="6">
              <span style="font-size: 18px;font-weight: 500;color: #868792;">可提现金额：</span>
              <span style="font-size: 18px;font-weight: 500;color: #202033;">{{data.can_withdraw}}</span>
            </el-col>
            <el-col :span="6">
              <span style="font-size: 18px;font-weight: 500;color: #868792;">保证金：</span>
              <span style="font-size: 18px;font-weight: 500;color: #202033;">{{data.security_deposit}}</span>
            </el-col>
          </el-row>

          <div style="margin-top: 20px; margin-bottom: 15px;">
            <div v-for="(item,index) in itemList" :key="index" class="kuaixuan" :class="{kuaixuan1:index==isShow}" @click="activeItem(item,index)">
              {{item.name}}
            </div>
          </div>
          <!--账户明细表格-->
          <div style="margin-top: 15px;">
            <my-table
              v-if="isShow === 0"
              ref="myTable"
              :columns="columns"
              :tableData="List"
              :headerCellStyle="headerCellStyle"
              :total="total"
              :customsFromWhere="where"
              :loading="loading"
              @select="select"
              @ChangeSize="ChangeSize"
              @currentChange="currentChange"
            >
              <!-- 不使用数据可不接收scope值 -->
              <!--<template v-slot:operationSlot= "{ scope }">-->
              <!--  <el-link :underline="false" type="warning">编辑</el-link>-->
              <!--  <el-link :underline="false" @click="del(scope.row)">删除</el-link>-->
              <!--</template>-->
            </my-table>

            <my-table
              v-else
              ref="myTable"
              :columns="columns1"
              :tableData="List"
              :headerCellStyle="headerCellStyle"
              :total="total"
              :customsFromWhere="where"
              :loading="loading"
              @select="select"
              @ChangeSize="ChangeSize"
              @currentChange="currentChange"
            >
              <!-- 不使用数据可不接收scope值 -->
              <!--<template v-slot:operationSlot= "{ scope }">-->
              <!--  <el-link :underline="false" type="warning">编辑</el-link>-->
              <!--  <el-link :underline="false" @click="del(scope.row)">删除</el-link>-->
              <!--</template>-->
            </my-table>

          </div>

        </el-card>

      </el-form>

      <!--按钮-->
      <!--<div class="card_bottom">-->
      <!--  <el-button style="width: 200px !important;">取消</el-button>-->
      <!--  <el-button type="warning" style="width: 200px !important; margin-left: 20px;">提交</el-button>-->
      <!--</div>-->
    </div>



  </div>
</template>

<script>

import {Detailsoutsource_drivers,getparticulars} from "@/api/yunli";

export default {
  name: 'index',
  // 支柱道具属性
  props: {
    // 字典数据
    dict: Object,
    // id
    id:Number
  },
  // 组件生效
  components: {

  },
  // Data数据
  data() {
    return {
      form:{},

      where:{
        type:'',
        page: 1, // 当前页数
        limit: 10 // 每页显示条目个数
      },
      data:{},
      // 表格数据
      List: [],
      // 总条目数
      total: 0,
      //加载动画
      loading:false,
      // 表头数据
      columns: [
        {
          type: "selection",
          isShow: true
        },
        {
          label: "到账时间",
          prop: "received_date",
          isShow: true
        },
        {
          label: "订单编号",
          prop: "order_number",
          isShow: true
        },
        {
          label: "服务类型",
          prop: "type",
          isShow: true
        },
        {
          label: "订单金额",
          prop: "number",
          isShow: true
        },
        {
          label: "信息费",
          prop: "message_fee",
          isShow: true
        },
        {
          label: "保险费",
          prop: "premium",
          isShow: true
        },
        {
          label: "到账金额",
          prop: "balance",
          isShow: true
        },
      ],

      columns1:[
        {
          type: "selection",
          isShow: true
        },
        {
          label: "名称",
          prop: "real_name",
          isShow: true
        },
        {
          label: "订单号",
          prop: "order_number",
          isShow: true
        },
        {
          label: "提现方式",
          prop: "extract_type",
          isShow: true
        },
        {
          label: "银行卡号",
          prop: "bank_code",
          isShow: true
        },
        {
          label: "微信号",
          prop: "wechat",
          isShow: true
        },
        {
          label: "支付宝账号",
          prop: "alipay_code",
          isShow: true
        },
        {
          label: "开户地址",
          prop: "bank_address",
          isShow: true
        },
        {
          label: "提现余额",
          prop: "extract_price",
          isShow: true
        },
        {
          label:'状态',
          prop:'status',
          isShow: true,
        },
        {
          label:'提现时间',
          prop:'withdraw_time',
          isShow: true,
        },
      ],

      itemList:[
        {
          name:'收入明细'
        },
        {
          name:'提现明细'
        }
      ],
      isShow:0,



    }
  },
  // 计算属性 类似于 data 概念
  computed: {},
  // 监控 data 中的数据变化
  watch: {
  },
  // 生命周期 - 创建完成（可以访问当前this 实例）
  created() {
  },
  // 生命周期 - 挂载完成（可以访问 DOM 元素）
  mounted() {
    this.getDetails();

    this.getMingxi();
  },
  // 方法集合
  methods: {
    //获取详情
    getDetails(){
      Detailsoutsource_drivers(this.id).then(res => {
        console.log(res);
        this.form = res.data;
      })
    },

    //获取账户明细接口
    getMingxi(){
      if(this.isShow == 0){
        this.where.type = 'money'
      }else {
        this.where.type = 'extract'
      }
      getparticulars(this.id,this.where).then(res => {
        console.log(res);
        this.total = res.data.total;
        this.data = res.data.particulars;
        this.List = res.data.list;
      })
    },

    //返回按钮
    returnPage(){
      this.$emit('showDetalis',false) ;
    },

    // 当前页数改变事件
    currentChange(val) {
      console.log("当前页数", val)
      this.where.page = val;
    },
    // 当用户手动勾选数据行的 Checkbox 时触发的事件
    select(selection, row) {
      console.log(selection)
      console.log("选择的所有数据行", selection)
      console.log("当前选择的数据行", row)
    },
    ChangeSize(val){
      console.log('当前页面显示的条数',val);
      this.where.limit = val;
      this.getList()
    },
    // 表头单元格的 style 的回调方法
    headerCellStyle({ row, column, rowIndex, columnIndex }) {
      return "background: #F7F8FA"
    },

    //选择
    activeItem(item,index){
      console.log(item);
      console.log(index);
      this.isShow = index;
      this.getMingxi();
    },


  },
}
</script>

<style scoped lang="scss">
  .span_l{
    display: inline-block;
    width: 115px;
    text-align: right;
  }
  .span_r{
    font-size: 15px !important;
  }

  .kuaixuan{
    background: #e8e8e8;
    border: 1px solid #dadada;
    border-radius: 6px;
    padding: 10px 15px 10px 15px;
    font-size: 14px;
    font-weight: 500;
    color: #7b7b7b;
    cursor: pointer;
    display: inline-block;
    margin-right: 15px;
  }
  .kuaixuan:hover{
    background: #FF9B05;
    border-color: #FF9B05;
    color: #FFFFFF;
  }
  .kuaixuan1{
    background: #FF9B05;
    border-color: #FF9B05;
    color: #FFFFFF;
  }
</style>
