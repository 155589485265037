<template>
  <!--合作司机列表-->
  <div style="padding: 20px;">
    <div v-if="showTechnicianEdit == false && ShowTechnicianDetails == false">
      <div class="title">
        {{ $route.meta.title }}
      </div>

      <!--搜索条件-->
      <el-card shadow="never" style="margin-top: 20px;">
        <div class="card">
          <div class="card_t">
            <div class="card_t_icon"></div>
            <div class="card_t_text">
              筛选条件
            </div>
          </div>
          <div class="card_b">
            <!-- 搜索表单 -->
            <el-form
                :model="where"
                label-width="77px"
                class="ele-form-search"
                @keyup.enter.native="reload"
                @submit.native.prevent>
              <el-row :gutter="15">
                <el-col :lg="6" :md="12">
                  <el-form-item label="关键词:">
                    <el-input
                        clearable
                        v-model="where.keyword"
                        placeholder="请输入关键词"/>
                  </el-form-item>
                </el-col>
                <el-col :lg="6" :md="12">
                  <div class="ele-form-actions">
                    <el-button
                        type="primary"
                        class="ele-btn-icon"
                        @click="QueryList">查询
                    </el-button>
                    <el-button @click="empty">清空</el-button>
                  </div>
                </el-col>
                <!--<el-col :lg="6" :md="12">-->
                <!--  <el-form-item label="司机状态:">-->
                <!--    <el-select-->
                <!--        clearable-->
                <!--        v-model="where.on_line"-->
                <!--        placeholder="请选择状态"-->
                <!--        class="ele-fluid">-->
                <!--      <el-option-->
                <!--          v-for="(item) in dict.type.Technician_status"-->
                <!--          :label="item.label"-->
                <!--          :value="item.value"-->
                <!--      />-->
                <!--    </el-select>-->
                <!--  </el-form-item>-->
                <!--</el-col>-->
                <!--<el-col :lg="6" :md="12">-->
                <!--  <el-form-item label="在线状态:">-->
                <!--    <el-select-->
                <!--        clearable-->
                <!--        v-model="where.approve"-->
                <!--        placeholder="请选择状态"-->
                <!--        class="ele-fluid">-->
                <!--      <el-option-->
                <!--          v-for="(item) in dict.type.online_state"-->
                <!--          :label="item.label"-->
                <!--          :value="item.value"-->
                <!--      />-->
                <!--    </el-select>-->
                <!--  </el-form-item>-->
                <!--</el-col>-->
                <!--<el-col :lg="6" :md="12">-->
                <!--  <el-form-item label="服务商:">-->
                <!--    <el-select-->
                <!--        clearable-->
                <!--        v-model="where.facilitator_id"-->
                <!--        placeholder="请选择状态"-->
                <!--        class="ele-fluid">-->
                <!--      <el-option v-for="(item) in facilitator" :label="item.label" :value="item.value"/>-->
                <!--    </el-select>-->
                <!--  </el-form-item>-->
                <!--</el-col>-->
              </el-row>
              <el-row :gutter="15">
                <!--<el-col :lg="6" :md="12">-->
                <!--  <el-form-item label="服务项目:">-->
                <!--    <el-select-->
                <!--        clearable-->
                <!--        v-model="where.items"-->
                <!--        placeholder="请选择"-->
                <!--        class="ele-fluid">-->
                <!--      <el-option v-for="(item) in ServicesAvailable" :label="item.label" :value="item.serial_number"/>-->
                <!--    </el-select>-->
                <!--  </el-form-item>-->
                <!--</el-col>-->
                <!--<el-col :lg="6" :md="12">-->
                <!--  <div class="ele-form-actions">-->
                <!--    <el-button-->
                <!--        type="primary"-->
                <!--        class="ele-btn-icon"-->
                <!--        @click="QueryList">查询-->
                <!--    </el-button>-->
                <!--    <el-button @click="empty">清空</el-button>-->
                <!--  </div>-->
                <!--</el-col>-->
              </el-row>
            </el-form>
          </div>
        </div>
      </el-card>

      <!--数据表格-->
      <el-card shadow="never">
        <div class="card">
          <div class="Datalist">
            <div class="Datalist_l">
              <div class="Datalist_icon"></div>
              <div class="Datalist_text">
                数据列表
              </div>
              <div class="custom" style="margin-left: 20px">
                <el-button class="button" type="primary" @click="openEdit(null)" v-auths="[`${$config.uniquePrefix}yunli:selfOperatedOutsourcedDrivers:save`]">
                  <!--el-icon-devops 是自己命名的-->
                  <i class="el-icon-tianjia" />
                  <span style="vertical-align: middle">新增司机 </span>
                </el-button>
                <!--<el-button class="custom-button">-->
                <!--  <i class="el-icon-devops" />-->
                <!--  <span style="vertical-align: middle">批量导入</span>-->
                <!--</el-button>-->
              </div>
            </div>
            <div>
              <!--<el-button>导出数据</el-button>-->
              <el-button @click="ListSetup()">列表设置</el-button>
            </div>
          </div>

          <div class="card_b">
            <!-- 数据表格 -->
            <my-table
                ref="myTable"
                :columns="columns"
                :tableData="List"
                :cellStyle="cellStyle"
                :headerCellStyle="headerCellStyle"
                :rowClassName="rowClassName"
                :total="total"
                :customsFromWhere="where"
                :loading="loading"
                @select="select"
                @ChangeSize="ChangeSize"
                @currentChange="currentChange"
            >

              <!--自定义列显示 示例-->
              <template v-slot:avatar= "{ scope }">
                <div>
                  <img style="cursor: pointer;width: 48px;height: 48px;border-radius: 50%;" :src="scope.row.avatar" alt="" v-if="scope.row.avatar != ''">
                  <img style="cursor: pointer;width: 60px;height: 60px;border-radius: 50%;" src="@/assets/images/home/touxiang1.png" alt="" v-else>
                </div>
              </template>

              <template v-slot:items_name= "{ scope }">
                <span v-for="(item) in scope.row.items_name" style="margin-right: 10px;">{{item}}</span>
              </template>

              <template v-slot:city= "{ scope }">
                <span v-for="(item) in scope.row.city" style="margin-right: 10px;">{{item}}</span>
              </template>

              <!-- 不使用数据可不接收scope值 -->
              <template v-slot:operationSlot= "{ scope }">
                <el-link :underline="false" type="warning" @click="ToDeatils(scope.row)" v-auths="[`${$config.uniquePrefix}yunli:selfOperatedOutsourcedDrivers:info`]">详情</el-link>
                <el-link :underline="false" type="warning" style="margin-right: 10px;" @click="openEdit(scope.row)" v-auths="[`${$config.uniquePrefix}yunli:selfOperatedOutsourcedDrivers:put`]">编辑</el-link>
                <el-popconfirm title="确定执行此操作吗？" @confirm="del(scope.row)">
                  <el-link slot="reference" :underline="false" v-auths="[`${$config.uniquePrefix}yunli:selfOperatedOutsourcedDrivers:delete`]">删除</el-link>
                </el-popconfirm>
              </template>
            </my-table>

          </div>
        </div>
      </el-card>

      <!--列表设置弹窗-->
      <div class="dialog">
        <el-dialog
            title="列表设置"
            :visible.sync="ListSetupShow"
            width="60%"
            center
            :before-close="handleClose">
        <span>
           <PackageSortElement :AllParameters="columns"   @change="SortList" @show="dialogshow"></PackageSortElement>
        </span>
        </el-dialog>
      </div>

    </div>

    <!--编辑司机模块-->
    <TechnicianEdit v-if="showTechnicianEdit == true" :data="TechnicianEditcurrent" :id="pid" :dict="dict" :visible.sync="showTechnicianEdit" @showEdit="ShowEdit" @done="getList"/>

    <!--司机详情信息-->
    <TechnicianDetails v-if="ShowTechnicianDetails == true" :id="pid" :dict="dict" @showDetalis="ShowDetalis"/>

  </div>
</template>

<script>
//引入编辑司机弹窗
import TechnicianEdit from '../selfOperatedOutsourcedDriversedit/edit.vue'
//引入司机详情信息模块
import TechnicianDetails from '../selfOperatedOutsourcedDriversedetails/index.vue'
// 引入表格设置封装的组件
import PackageSortElement from "@/components/PackageSortElement";
// 权限
import {ShowTable} from '@/utils'
// 引入的接口
import {
  Deloutsource_driver,
  getoutsource_driver,
  getget_services_available,
  facilitatorselect
} from "@/api/yunli";
import Vue from "vue";


export default {
  // 组件生效
  components: {
    PackageSortElement,
    TechnicianEdit,
    TechnicianDetails
  },
  data(){
    return{
      // 表格搜索条件
      where: {
        page: 1, // 当前页数
        limit: 10 // 每页显示条目个数
      },
      // 表格数据
      List: [],
      // 总条目数
      total: 0,
      //加载动画
      loading:true,
      // 表头数据
      columns: [
        {
          type: "selection",
          isShow: true
        },
        // {
        //   label: "系统编号",
        //   // type: "index",
        //   // width: "70"
        //   prop: "id",
        //   isShow: true,
        //   fixed: 'left'
        // },
        {
          label: "姓名",
          prop: "realname",
          isShow: true
        },
        {
          label: "车牌号",
          prop: "",
          isShow: true
        },
        {
          label: "手机号",
          prop: "phone",
          isShow: true
        },
        {
          label: "车牌号码",
          prop: "license_plate_number",
          isShow: true
        },
        {
          label: "所属服务商",
          prop: "facilitator_name",
          isShow: true
        },
        {
          label: "头像",
          slot: "avatar",
          prop: "avatar",
          isShow: true
        },
        {
          label: "服务项目",
          width: '140',
          prop: "items_name",
          slot: 'items_name',
          isShow: true
        },
        {
          label: "实名状态",
          prop: "autonym_status_name",
          width: '90',
          isShow: true
        },
        {
          label: "司机状态",
          prop: "status_name",
          isShow: true
        },
        {
          label: "在线状态",
          prop: "on_line_name",
          isShow: true
        },
        {
          label: "服务区域",
          prop: "city",
          slot: 'city',
          width: '180',
          isShow: true
        },
        {
          label: "上线时间",
          prop: "create_time",
          width: '160',
          isShow: true
        },
        {
          label: "操作",
          width: "200",
          slot: "operationSlot",
          isShow: true,
          fixed: 'right'
        },
      ],

      // 列表设置弹窗
      ListSetupShow:false,

      //编辑司机模块
      TechnicianEditcurrent:null,
      showTechnicianEdit:false,

      //司机详情模块
      ShowTechnicianDetails:false,

      //字典
      dict: {
        type:{}
      },

      //服务项目
      ServicesAvailable:[],
      //服务商
      facilitator:[],

      pid:0,

    }
  },

  // 生命周期 - 挂载完成（可以访问 DOM 元素）
  mounted() {
    // 初始化dict
    this.$store.getters.dict.forEach(item => {
      if (item.name === '运力字典'){
        item.children.forEach(res => {
          Vue.set(this.dict.type, res.code, res.withChildren);
        })
      }
    });
    console.log(this.dict)

    //调用获取客户信息列表方法
    this.getList();
    //获取服务项目
    // this.getFuwuxiangmu();
    //获取服务商下拉
    // this.getFuwushang();
  },

  methods:{
    //获取服务项目
    getFuwuxiangmu(){
      getget_services_available().then(res => {
        this.ServicesAvailable = res.data;
      })
    },

    //获取服务商下拉
    getFuwushang(){
      facilitatorselect().then(res => {
        this.facilitator = res.data;
      })
    },

    // 获取列表
    getList(){
      getoutsource_driver(this.where).then(async (res) => {
        this.total = res.data.count;
        this.List = res.data.list;
        this.loading = false;
      }).catch((res) => {
        this.$Message.error(res.msg);
        this.loading = false;
      })
    },

    // 查询条件
    QueryList(){
      this.getList();
    },
    // 清空
    empty(){
      this.where = {
        page: 1, // 当前页数
        limit: 10 // 每页显示条目个数
      }
      this.getList();
    },

    // 当前页数改变事件
    currentChange(val) {
      console.log("当前页数", val)
      this.where.page = val;
      this.getList();
    },
    // 当用户手动勾选数据行的 Checkbox 时触发的事件
    select(selection, row) {
      console.log(selection)
      console.log("选择的所有数据行", selection)
      console.log("当前选择的数据行", row)
    },
    ChangeSize(val){
      console.log('当前页面显示的条数',val);
      this.where.limit = val;
      this.getList()
    },

    // 表头行的 className 的回调方法
    headerRowClassName({ row, rowIndex }) {
      return "header_row_class_name"
    },
    // 表头单元格的 style 的回调方法
    headerCellStyle({ row, column, rowIndex, columnIndex }) {
      return "background: #F7F8FA"
    },
    // 行的 className 的回调方法
    rowClassName({ row, rowIndex }) {
      return "row_class_name"
    },
    // 单元格的 style 的回调方法
    cellStyle({ row, column, rowIndex, columnIndex }) {
      if (rowIndex == 1 && columnIndex == 3) {
        // return {
        //   background: "skyBlue",
        //   "border-radius": "20px"
        // }
      }
    },
    // 仅对 type=selection 的列有效，Function(row, index) 用来决定这一行的 CheckBox 是否可以勾选
    selectable(row, index) {
      if (row.sex == "女") {
        return false
      } else {
        return true
      }
    },

    // 删除
    del(row){
      console.log(row);
      Deloutsource_driver(row.id).then(res => {
        console.log(res)
        if(res.code === 200){
          this.$message.success(res.msg);
          this.getList();
        }else {
          this.$message.success(res.msg);
        }
      }).catch(e => {
        this.$message.error(e.msg)
      })
    },

    // 点击列表设置
    ListSetup(){
      this.ListSetupShow = true;
    },
    // 列表设置关闭
    handleClose(){
      this.ListSetupShow = false;
    },
    //事件  拿到从组件内部处理的数据顺序并赋值
    SortList(value){
      // 接收子组件传递的值替换当前列表显示顺序
      this.columns = value;
    },
    dialogshow(value){
      this.ListSetupShow = value;
    },


    // 接收 编辑子组件传递的值
    ShowEdit(value){
      console.log(value)
      // 隐藏编辑子组件
      this.showTechnicianEdit = value;
    },

    //点击添加修改
    openEdit(row){
      console.log(row);
      this.showTechnicianEdit = true;
      this.TechnicianEditcurrent = row;
    },
    //点击详情按钮
    ToDeatils(row){
      console.log(row);
      console.log(row.id);
      this.pid = row.id;
      this.ShowTechnicianDetails = true;
    },

    ShowDetalis(value){
      // 隐藏详情子组件
      this.ShowTechnicianDetails = value;
    },

  }

}
</script>

<style lang="scss" scoped>

</style>
